@mixin standardList() {
    padding: 0;
	margin: 0;
	list-style-type: none;
}
@mixin standardRowList() {
    padding: 0;
	margin: 0 $rowMargin;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
}
@mixin slider() {
	position: relative;
	white-space: nowrap;
	font-size: 0;
	overflow: hidden;
}
@mixin slide($width, $position) {
	display: inline-block;
	white-space: normal;
	font-size: 1rem;
	width: $width;
	vertical-align: $position;
}
@mixin centerCover() {
	background-size: cover;
	background-position: center;
}
@mixin animStyle($parameters) {
  	-o-transition: $parameters;
	-ms-transition: $parameters;
	-moz-transition: $parameters;
	-webkit-transition: $parameters;
	transition: $parameters;
}
@mixin animStyles($param1,$param2) {
	-o-transition: $param1,$param2;
  -ms-transition: $param1,$param2;
  -moz-transition: $param1,$param2;
  -webkit-transition: $param1,$param2;
  transition: $param1,$param2;
}
@mixin transStyle($parameters) {
	transform: $parameters;
	-moz-transform: $parameters;
    -ms-transform: $parameters;
    -webkit-transform: $parameters;
    -o-transform: $parameters;
}
@mixin torigin($parameters) {
	transform-origin: $parameters;
	-webkit-transfrom-origin: $parameters;
}
@mixin animDelay($parameters) {
	-o-transition-delay: $parameters;
	-ms-transition-delay: $parameters;
	-moz-transition-delay: $parameters;
	-webkit-transition-delay: $parameters;
	transition-delay: $parameters;
}
@mixin dropArrow() {
	color: inherit;
	border-style: solid;
	border-left-color: transparent;
	border-right-color: transparent;
}
@mixin overHidden() {
	position: relative;
	overflow: hidden;
}
@mixin psevdoAbs() {
	display: block;
	content: "";
	position: absolute;
}
@mixin coverLayer() {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
@mixin maskedInput() {
	position: absolute;
	width: 0;
	height: 0;
	top: 0;
	left: 0;
	visibility: hidden;
}
@mixin colCount($col) {
	-moz-column-count: $col;
    -webkit-column-count: $col;
    column-count: $col;
}
@mixin colGap($gap) {
    -webkit-column-gap: $gap;
    -moz-column-gap: $gap;
    column-gap: $gap;
}

@mixin customScroll($width, $height, $radius, $trackBg, $barBg) {
	&::-webkit-scrollbar {
		width: $width;
		height: $height;
		border-radius: $radius;
		background:  $trackBg;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: $radius;
		background: $barBg;
	}
}

@mixin scrollbarY($width,$radius,$trackBg,$barBg,$barHoverBg) {
	.scroll-y {
        div {
            position: absolute;
            display: block;
            top: 0;
			bottom: 0;
			right: 0;
            border-radius: $radius;
            width: $width;
            &.scroll-element_track {
                z-index: 2;
                background: $trackBg;
            }
            &.scroll-bar {
                cursor: pointer;
                z-index: 3;
                background: $barBg;
                @extend %back300;
                &:hover {
                  background: $barHoverBg;
                }
            }
        }
    }
}

@mixin textOverflow($rowCount,$position) {
	position: $position;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $rowCount;
	-webkit-box-orient: vertical;
}

@mixin popupBody($background,$opacity, $transition) {
	&:before {
		display: block;
		position: fixed;
		content: "";
		z-index: 999;
		top: 0;
		left: 0;
		width: 100%;
		background: $background;
		opacity: 0;
		height: 0;
		@include animStyles(height 0.05s, opacity $transition);
		@include animDelay($transition);
   }
   &.popup_opened {
	   overflow: hidden;
	   &:before {
			height: 100%;
			opacity: $opacity;
			@include animDelay(0s);
	   }
   }
}

@mixin popupBlock($transition) {
	position: fixed;
	left: 0;
	width: 100%;
	height: 100%;
	top: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 1000;
	@include animStyle(top $transition);
	&.showed {
		top: 0;
	}
}

@mixin popupInner($padding) {
	display: flex;
	width: 100%;
	min-height: 100%;
	justify-content: center;
	align-items: center;
	padding: $padding;
}
@mixin popupContainer($background,$maxWidth, $padding) {
	background: $background;
	width: 100%;
	max-width: $maxWidth;
	padding: $padding;
	position: relative;
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}  
}
